document.addEventListener('DOMContentLoaded', function () {
	const loginForm = document.querySelector('.login-form');
	const submitButtons = loginForm?.querySelectorAll?.('.submit-button');
	const emailInput = document.querySelector('#email');
	const checkConsent = document.querySelector('#user-consent');

	if (!loginForm || !submitButtons || !emailInput) {
		return;
	}

	// Function to check if the consent checkbox is checked
	function isConsentChecked() {
		return checkConsent.checked;
	}

	if (checkConsent) {
		checkConsent.addEventListener('change', function () {
			for (const button of submitButtons) {
				button.disabled = !isConsentChecked();
				button.classList.toggle('btn-solid--disabled', !isConsentChecked());
			}
		});
	}

	loginForm.addEventListener('submit', (e) => {
		e.preventDefault();

		// Validate the email input before submission
		if (!emailInput.checkValidity()) {
			emailInput.reportValidity();
			return;
		}

		for (const button of submitButtons) {
			button.disabled = true;
		}

		loginForm.submit();
	});

	for (const button of submitButtons) {
		button.addEventListener('click', (e) => {
			if (emailInput.checkValidity()) {
				const spinner = button.querySelector('.spinner');

				if (spinner) {
					spinner.classList.remove('hidden');
				}
			} else {
				emailInput.reportValidity(); // Optional: Trigger the browser's error message on button click
			}
		});
	}
});
